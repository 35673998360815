const polyhedron = {
	redraw: null,

	// 初期化
	init: function(canvid) {
		var obj = this.object;
		var pref = this.pref;

		var canv = document.getElementById(canvid);
		var ctx;
		if (canv && canv.getContext) {
			ctx = canv.getContext('2d');
		} else {
			return null;
		}
		var canvc = [canv.width / 2, canv.height / 2, 0];

		// 現在の立体を回転させ、座標の情報を更新する
		var rotate = function(xz, yz) {
			var p = obj.points;
			var f = obj.faces;
			for (var i = 0; p.length > i; i++) {
				p[i] = p[i].rot(xz, yz);
			}
			for (var i = 0; f.length > i; i++) {
				var tmp = 0;
				for (var j = 0; f[i].length > j; j++) {
					tmp += p[f[i][j]][2];
				}
				f[i].zc = tmp / f[i].length;
				f[i].dir = p[f[i][0]].sub(p[f[i][1]]).vp(p[f[i][2]].sub(p[f[i][1]])).normalize();
			}
			f.sort(function(a, b) { return (a.zc >= b.zc) ? -1 : 1; });
		};

		// 現在の立体をcanvasに描く
		var draw = function() {
			var p = obj.points;
			var f = obj.faces;
			var c = pref.colorset[pref.color];
			var mode = pref.mode;
			ctx.clearRect(0, 0, canv.width, canv.height);
			ctx.lineWidth = 1.5;
			ctx.lineJoin = 'bevel';
			ctx.strokeStyle = 'rgb(' + c[3] + ',' + c[4] + ',' + c[5] + ')';
			for (var i = 0; f.length > i; i++) {
				var inv = f[i].invertmode;
				var hln = f[i].hiddenline ? f[i].hiddenline : 0;
				var rev = f[i].noreverse;
				var fdir = (0 > f[i].dir[2]);

				// その面が手前向きか、全ての面を描く設定の場合のみ
				if (fdir || pref.allfaces || pref.alllines) {

					// 面を構成する点を取り出し、遠近感を持たせるため、座標をずらす
					var path = [];
					for (var j = 0; f[i].length > j; j++) {
						var ptmp = p[f[i][j]];
						path.push(ptmp.mul(pref.screen / (pref.depth + ptmp[2])).add(canvc));
						//path.push(ptmp.add([0.3, 0, 0]).mul(pref.screen / (pref.depth + ptmp[2])).add([0, 0, 0]).add(canvc));
					}
					path.push(path[0]);

					// 面を描く設定の場合、面を描く
					if ((fdir || pref.allfaces) && ((mode == 1 && !inv) || (mode == 2 && inv) || mode == 3)) {
						// 光源との角度によって色を変える（0.0～0.1）
						var br = !rev
							? (f[i].dir.dp(pref.light) + 1) * 0.5
							: (((f[i].dir[2] > 0) ? f[i].dir.mul(-1) : f[i].dir).dp(pref.light) + 1) * 0.5;
						var br = (f[i].dir.mul(fdir ? 1 : -1).dp(pref.light) + 1) * 0.5;
						// br = 1;
						ctx.fillStyle = 'rgba(' + (br * c[0] | 0) + ',' + (br * c[1] | 0) + ',' + (br * c[2] | 0) + ',' + pref.tr + ')';

						if (i == 0 || i == 19) {
							ctx.fillStyle = 'rgba(' + 37 + ',' + 152 + ',' + 229 + ',' + pref.tr + ')';
						} else if (i == 1 || i == 18) {
							ctx.fillStyle = 'rgba(' + 35 + ',' + 148 + ',' + 227 + ',' + pref.tr + ')';
						} else if (i == 2 || i == 17) {
							ctx.fillStyle = 'rgba(' + 33 + ',' + 144 + ',' + 226 + ',' + pref.tr + ')';
						} else if (i == 3 || i == 16) {
							ctx.fillStyle = 'rgba(' + 30 + ',' + 141 + ',' + 224 + ',' + pref.tr + ')';
						} else if (i == 4 || i == 15) {
							ctx.fillStyle = 'rgba(' + 28 + ',' + 137 + ',' + 223 + ',' + pref.tr + ')';
						} else if (i == 5 || i == 14) {
							ctx.fillStyle = 'rgba(' + 26 + ',' + 133 + ',' + 221 + ',' + pref.tr + ')';
						} else if (i == 6 || i == 13) {
							ctx.fillStyle = 'rgba(' + 24 + ',' + 129 + ',' + 220 + ',' + pref.tr + ')';
						} else if (i == 7 || i == 12) {
							ctx.fillStyle = 'rgba(' + 21 + ',' + 126 + ',' + 218 + ',' + pref.tr + ')';
						} else if (i == 8 || i == 11) {
							ctx.fillStyle = 'rgba(' + 19 + ',' + 122 + ',' + 217 + ',' + pref.tr + ')';
						} else if (i == 9 || i == 10) {
							ctx.fillStyle = 'rgba(' + 17 + ',' + 118 + ',' + 215 + ',' + pref.tr + ')';
						}

						// console.log(i + 'は' +ctx.fillStyle);
						ctx.beginPath();
						ctx.moveTo(path[0][0], path[0][1]);
						for (var k = 1; path.length > k; k++) {
							ctx.lineTo(path[k][0], path[k][1]);
						}
						ctx.fill();
					}

					// 線を描く設定の場合、線を描く
					if ((fdir || pref.alllines) && ((mode == 1 && inv) || (mode == 2 && !inv) || mode == 3)) {
						ctx.beginPath();
						ctx.moveTo(path[0][0], path[0][1]);
						for (var k = 1; path.length > k; k++) {
							// 線を引かない辺はmoveToで飛ばす
							if ((hln >> k - 1) & 1) {
								ctx.moveTo(path[k][0], path[k][1]);
							} else {
								ctx.lineTo(path[k][0], path[k][1]);
							}
						}
						ctx.stroke();
					}
				}
			}
		};

		// マウス操作
		// var rot = { sx : 0, sy : 0, t : false };
		// canv.onmousedown = function(e) {
		// 	rot.sx = e ? e.clientX : event.x;
		// 	rot.sy = e ? e.clientY : event.y;
		// 	rot.t = true;
		// };
		// document.onmousemove = function(e) {
		// 	if (rot.t) {
		// 		var xtmp = e ? e.clientX : event.x;
		// 		var ytmp = e ? e.clientY : event.y;
		// 		// console.log(xtmp);
		// 		rotate((xtmp - rot.sx) / 5 / 180 * Math.PI, (ytmp - rot.sy) / 5 / 180 * Math.PI);
		// 		rot.sx = xtmp;
		// 		rot.sy = ytmp;
		// 		draw();
		// 	}
		// };
		// document.onmouseup = function(e) {
		// 	rot.t = false;
		// };

		// 一定時間ごとに回転
		let xrote = 1;
		let yrote = 0.5;
		const countUp = function() {
			rotate((xrote - 0) / 5 / 180 * Math.PI, (-yrote - 0) / 5 / 180 * Math.PI);
			draw();
		}
		setInterval(countUp, 30);

		// 再描画
		this.redraw = function(reset) {
			if (reset) {
				var a = pref.angle ? pref.angle : [0, 0];
				rotate(a[0] * Math.PI / 180, a[1] * Math.PI / 180);
			}
			draw();
		};
	},

	// 立体のデータ
	object: {
		points: [],
		faces : []
	},

	// 立体を変更
	setObject: function(type) {

		// 定義から立体のデータを取得
		var obj = this.polydef[type](this.point);

		// 大きさをそろえる（各頂点の原点からの距離の平均を1にする）
		var s = 0;
		for (var i = 0; obj.points.length > i; i++) {
			s += obj.points[i].abs(); // → いまいちそろわない…
			//s += Math.sqrt(obj.points[i].abs());
		}
		s = obj.points.length / s;
		for (var i = 0; obj.points.length > i; i++) {
			obj.points[i] = obj.points[i].mul(s);
		}
		// this.object = obj; // ← これだとinit()内のクロージャが見失う
		this.object.points = obj.points;
		this.object.faces = obj.faces;

	},

	// 各種メソッドを備えた座標（位置ベクトル）オブジェクトを生成する
	point: function(x, y, z) {
		var t = [x, y, z];
		var point = arguments.callee; // 自分自身
		t.add = function(p) { // 和
			return point(this[0] + p[0], this[1] + p[1], this[2] + p[2]);
		};
		t.sub = function(p) { // 差
			return point(this[0] - p[0], this[1] - p[1], this[2] - p[2]);
		};
		t.mul = function(r) { // 定数倍
			return point(this[0] * r, this[1] * r, this[2] * r);
		};
		t.vp = function(p) { // 外積
			return point(this[1] * p[2] - this[2] * p[1], this[2] * p[0] - this[0] * p[2], this[0] * p[1] - this[1] * p[0]);
		};
		t.dp = function(p) { // 内積
			return this[0] * p[0] + this[1] * p[1] + this[2] * p[2];
		};
		t.abs = function() { // 絶対値
			return Math.sqrt(this.dp(this));
		};
		t.normalize = function() { // 正規化
			return this.mul(1 / this.abs());
		};
		t.intdiv = function(p, r) { // 内分点
			return p.sub(this).mul(r).add(this);
		};
		t.rot = function(xz, yz) { // 回転
			var cxz = Math.cos(xz), sxz = Math.sin(xz), cyz = Math.cos(yz), syz = Math.sin(yz);
			var tmp = point(this.dp([cxz, 0, -sxz]), this[1], this.dp([sxz, 0, cxz]));
			return point(tmp[0], tmp.dp([0, cyz, -syz]), tmp.dp([0, syz, cyz]));
		};
		t.copy = function() { // 複製
			return point(this[0], this[1], this[2]);
		};
		return t;
	},

	// 立体の定義
	polydef: {
		// 正八面体
		octa: function(p) {
			var obj = { points: [], faces: [] };
			for (var i = 0; 6 > i; i++) {
				var j = i >> 1;
				var ptmp = [i - (i ^ 1), 0, 0];
				obj.points.push(p(ptmp[(3 - j) % 3], ptmp[(4 - j) % 3], ptmp[(5 - j) % 3]));
			}
			for (var i = 0; 4 > i; i++) {
				obj.faces.push([4, i, i ^ (i >> 1 | 2)], [5, i, i ^ (i >> 1 ^ 1 | 2)]);
			}
			return obj;
		},
		// 正二十面体
		icosa: function(p) {
			var obj = { points: [], faces: [] };
			var otmp = this.octa(p);
			var G = (Math.sqrt(5) - 1) / 2;
			var s = [1, 2, 0, 2, 2, 5, 5, 0, 3, 5, 0, 3, 1, 3, 3, 4, 4, 1, 2, 4, 5, 1, 4, 0];
			for (var i = 0; s.length > i; i += 2) {
				obj.points.push(otmp.points[s[i]].intdiv(otmp.points[s[i + 1]], G));
			}
			for (var i = 0; 10 > i; i += 2) {
				obj.faces.push([i, i + 1, (i + 2) % 10], [(i + 3) % 10, (i + 2) % 10, i + 1]);
			}
			for (var i = 0; 10 > i; i += 2) {
				obj.faces.push([10, i, (i + 2) % 10], [11, (i + 3) % 10, i + 1]);
			}
			return obj;
		}
	},

	// 設定項目
	pref: {
		// 面の向きに関わらず全ての面を描くか否か
		allfaces: true,
		// 面の向きに関わらず全ての線を描くか否か
		alllines: true,
		// 視点からスクリーンまでの距離
		screen: 1550,
		// 視点からz座標原点まで距離（screen > depth）
		depth: 4,
		// デフォルトの角度（y軸とx軸の回転角）
		angle: [0, 0],
		// 色セットの番号
		color: 0,
		// 光源の方向ベクトル（大きさは1）
		light: [1, 0, 0],
		// 面の透明度
		tr: 1.0,
		// 描画モード（1:面のみ、2:線のみ、3:面と線）
		mode: 3,
		// 色のセット
		colorset: [
			// [面のR, 面のG, 面のB, 線のR, 線のG, 線のB], // 0-255
			[105, 221, 255, 255, 255, 255],
			[0, 143, 230, 255, 255, 255],
			[0, 180, 240, 255, 255, 255],
			[0, 0, 0, 255, 255, 255],
		]
	},

	// 立体の定義の追加
	addObjectDefinition: function(def) {
		for (var k in def) {
			this.polydef[k] = def[k];
		}
	},

	// デフォルトの角度を変更
	setDefaultAngle: function(xz, yz) {
		this.pref.angle = [xz, yz];
	},

	// 色番号を変更
	setColorNumber: function(n) {
		this.pref.color = n;
	},

	// 光源の方向を変更
	setLightDirection: function() {
		var point = this.point;
		var a = arguments;
		if (a.length == 2) {
			this.pref.light = point(1, 0, 0).rot(a[0] * Math.PI / 180, a[1] * Math.PI / 180);
		} else if (a.length == 3) {
			this.pref.light = point(a[0], a[1], a[2]).normalize();
		}
	},

	// 面の透明度を変更
	setTransparency: function(n) {
		this.pref.tr = n;
	},

	// 描画モードを変更
	setDrawMode: function(n) {
		this.pref.mode = n;
	}
};
